import React from 'react'
import Layout, { LAYOUT_TYPES } from '../modules/Layout'
import { graphql } from 'gatsby'
import Main from '../modules/Main'
import useSeo from '../hooks/useSeo'
import { TEMPLATE_TYPES } from '../common/constants'

const DATA_MAP = {
  title: ['name'],
}

const getLayout = (data, helmetProps) => {
  const {
    heroHeadline,
    heroDescription,
    heroImage,
    heroVideo,
    content,
    callToActionHeadline,
    callToActionDescription,
    callToAction,
    relatedTestimonials,
    actionBar,
  } = data

  // Checks if the landing page sets a description, otherwise use the description of the Form embedded as CTA. This prevents duplication of the description, but allows overriding for specific campaigns
  const descriptionToUse =
    callToActionDescription && callToActionDescription
      ? callToActionDescription
      : callToAction && callToAction.description && callToAction.description
      ? callToAction.description
      : null

  const hasMediaLayout = heroVideo || heroImage

  const mediaLayout = heroVideo
    ? { __typename: LAYOUT_TYPES.VIDEO, ...heroVideo }
    : heroImage
    ? {
        __typename: LAYOUT_TYPES.IMAGE,
        ...heroImage,
      }
    : null

  const quotes = relatedTestimonials ? relatedTestimonials.map((t) => ({ __typename: LAYOUT_TYPES.QUOTE, ...t })) : []

  return [
    mediaLayout,
    {
      __typename: LAYOUT_TYPES.HERO,
      headline: heroHeadline,
      content: heroDescription,
      style: {
        pb: hasMediaLayout ? [4, 4, 4, 4, 10] : [4, 4, 4, 4, 4],
        pt: [4, 4, 4, 4, 10],
      },
    },
    {
      __typename: LAYOUT_TYPES.ACTION_BAR,
      ...actionBar,
    },
    {
      __typename: LAYOUT_TYPES.LEFT_RIGHT,
      layout: [
        {
          __typename: LAYOUT_TYPES.CONTENT_GENERIC,
          content,
          style: {
            pl: [4, 4, 4, 4, 20],
            pr: [4, 4, 4, 4, 4],
            pt: [0],
            justifyContent: 'left',
          },
        },
        {
          __typename: LAYOUT_TYPES.FORM,
          ...callToAction,
          title: callToActionHeadline,
          description: descriptionToUse,
        },
      ],
      style: {
        bg: 'brandBeigeLight',
        pb: [0],
        pt: hasMediaLayout ? [4, 4, 4, 4, 10] : [4, 4, 4, 4, 4],
        columns: [
          {
            position: 'static',
          },
          {
            top: '7rem',
            position: 'sticky',
          },
        ],
        alignItems: 'flex-start',
      },
    },
    ...quotes,
    {
      __typename: LAYOUT_TYPES.HELMET,
      ...helmetProps,
    },
  ]
}

const LandingPageTemplate = (props) => {
  const {
    data: { contentfulLandingPage },
  } = props

  const url = props.location.href
  const seoProps = useSeo(contentfulLandingPage, DATA_MAP, TEMPLATE_TYPES.PAGE)
  const layout = getLayout(contentfulLandingPage, seoProps)

  return (
    <Main {...props} path={url}>
      <Layout layout={layout} path={url} />
    </Main>
  )
}

export default LandingPageTemplate

export const pageQuery = graphql`
  query landingPageQuery($slug: String!) {
    contentfulLandingPage(slug: { eq: $slug }) {
      __typename
      id
      contentful_id
      name
      slug
      heroHeadline
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        ... on ContentfulImage {
          # ...Image
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      heroVideo {
        ... on ContentfulVideo {
          ...Video
        }
      }
      content: contentLandingPage {
        raw
        references {
          __typename
          ... on ContentfulHeading {
            ...HeadingFields
          }
          ... on ContentfulImage {
            contentful_id
            altText
            image {
              title
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          ... on ContentfulAsset {
            contentful_id
            title
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            caption: description
          }
          ... on ContentfulDestinations {
            contentful_id
            slug
          }
          ... on ContentfulPage {
            contentful_id
            id
            name
            slug
          }
          ... on ContentfulQuote {
            ...Quote
          }
          ... on ContentfulVideo {
            ...Video
          }
          ... on ContentfulPosts {
            contentful_id
            slug
            date
            year: date(formatString: "YYYY")
            month: date(formatString: "MM")
            postType {
              name
            }
            category {
              name
            }
          }
          ... on ContentfulActionBar {
            contentful_id
            ...ActionBar
          }
          ... on ContentfulLandingPage {
            contentful_id
            id
            name
            slug
          }
        }
      }
      callToActionHeadline
      callToActionDescription: childContentfulLandingPageCallToActionDescriptionTextNode {
        childMarkdownRemark {
          html
        }
      }
      callToAction {
        ... on ContentfulForm {
          ...FormFields
        }
      }
      relatedTestimonials {
        ... on ContentfulQuote {
          ...Quote
        }
      }
      actionBar {
        ... on ContentfulActionBar {
          ...ActionBar
        }
      }
      structuredData {
        schema {
          id
          name
          price
          priceCurrency
        }
        altText
        noIndex
        noFollow
        description {
          description
        }
        headline
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`
